@font-face {
  font-family: 'SF Pro Text Regular';
  src: local('SF Pro Text Regular'), url('./fonts/SFPro/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text Bold';
  src: local('SF Pro Text Bold'), url('./fonts/SFPro/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans Regular';
  src: local('OpenSans Regular'), url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans Medium';
  src: local('OpenSans Medium'), url('./fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
}
